import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import Payment from 'payment';
import { navigate } from 'gatsby';

import Button from 'components/button';
import HeaderBar from 'components/header-bar';
import Radio from 'components/radio';
import Switcher from 'components/switcher';
import InputText from 'components/input-text';
import { MemberValidationDialog, WalletDialog } from 'components/dialog';
import BottomSheet from 'components/bottom-sheet';

import IconNotice from 'images/icons/i-notice.svg';
import IconQuestion from 'images/icons/i-question.svg';
import IconWallet from 'images/icons/c4wallet-2.svg';
import IconMastercard from 'images/icons/i-mastercard.png';
import IconLinepay from 'images/icons/i-linepay.svg';
import IconRightArrow from 'images/icons/angle-arrow-right-black-s.svg';
import IconJko from 'images/icons/jkopay.png';
import IconApplePay from 'images/icons/Apple-Pay-Mark.png';

import { useAPI } from 'utils/api';
import { showLoading, dismissLoading } from 'utils/loading';
import WebFn, { callAppFunc } from 'utils/jsbridge';

const PaymentOptionPage = ({ location }) => {
  const api = useAPI();
  const isPointValid = useRef(false);
  const dispatch = useDispatch();
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);

  const clpeRatio = useRef(location.state.clpeRatio);
  const currentPayment = useRef(location.state.payment);

  const payment = useSelector((state) => getters.getPayment(state));
  const creditInfo = useSelector((state) => getters.getCreditInfo(state));
  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const paymentMethods = useSelector((state) =>
    getters.getPaymentMethods(state)
  );
  const newCardInfo = useSelector((state) => getters.getNewCards(state));

  const [addMode, setAddMode] = useState(false);
  const [cardNo, setCardNo] = useState('');
  const [cvc, setCvc] = useState('');
  const [expire, setExpire] = useState('');
  const [checked, setChecked] = useState(false);
  const [limitPrice, setLimitPrice] = useState(
    location.state.productTotal -
      location.state.discount -
      location.state.bonusThreshold <
      0
      ? location.state.bonusThreshold -
          (location.state.productTotal - location.state.discount)
      : 0
  );
  const [canUsePrice, setCanUsePrice] = useState(0);
  const [totalBonus, setTotalBonus] = useState(0);
  const [usePrice, setUsePrice] = useState();
  const [selectPayment, setSelectPayment] = useState(1);
  const [showWalletDialog, setShowWalletDialog] = useState(false);
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [usePointButtonState, setUsePointButtonState] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState({ jko: false });

  const onCardSubmit = (event) => {
    event.preventDefault();
    const newCardData = {
      cardNo: event.target[0].value,
      expire: event.target[1].value,
      cvc: event.target[2].value,
      checked: checked,
    };
    if (validCardInfo(newCardData)) {
      var selectIndexValue = 1000;
      if (
        newCardInfo !== undefined &&
        newCardInfo !== null &&
        newCardInfo.length > 0
      ) {
        selectIndexValue = newCardInfo.length + 1000;
      }
      dispatch(actions.setNewCards([...newCardInfo, newCardData]));
      setSelectPayment(selectIndexValue);
      setAddMode(false);
    }
  };

  const validCardInfo = (info) => {
    const cardValid = Payment.fns.validateCardNumber(info.cardNo);
    if (!cardValid) {
      alert('卡號輸入錯誤');
      return false;
    }
    const expireValid = Payment.fns.validateCardExpiry(info.expire);
    if (!expireValid) {
      alert('有效日期輸入錯誤');
      return false;
    }
    const cvcValid = Payment.fns.validateCardCVC(info.cvc);
    if (!cvcValid) {
      alert('安全碼輸入錯誤');
      return false;
    }
    return true;
  };

  const getPoint = () => {
    api
      .getBonusPoint()
      .then((res) => {
        if (!res) return;
        const point = res.point;
        isPointValid.current = res?.valid;
        handlePointFormula(point);
        if (!isPointValid.current) {
          res?.validreason !== ''
            ? alert(res?.validreason)
            : alert('系統錯誤，請聯絡客服。');
        }
      })
      .catch(() => {});
  };

  const validCode = (code, callback) => {
    showLoading();
    api
      .validCode({ mobile: userInfo.mobile, code })
      .then((res) => {
        dismissLoading();
        if (!res) {
          if (callback) {
            callback(false);
          }
          return;
        }
        if (callback) {
          callback(true);
        }
      })
      .catch((error) => {
        dismissLoading();
      });
  };

  const sendValidCode = (callback) => {
    showLoading();
    api
      .sendValidCode({ mobile: userInfo.mobile })
      .then((res) => {
        dismissLoading();
        if (!res) {
          if (callback) {
            callback(false);
          }
          return;
        }
        if (callback) {
          callback(true);
        }
      })
      .catch((error) => {
        dismissLoading();
      });
  };

  const onChangePayment = (event) => {
    setSelectPayment(Number(event.target.value));
  };

  const onChangeCreditCard = (event) => {
    Payment.formatCardNumber(event.target);
  };

  const onChangeCreditCVC = (event) => {
    Payment.formatCardCVC(event.target);
  };

  const onChangeExpire = (event) => {
    Payment.formatCardExpiry(event.target);
  };

  const onChangePriceValue = (event) => {
    const maxPrice = canUsePrice || 0;
    const currentPrice = Number(event.target.value);
    if (!/^\d*$/.test(currentPrice)) return;
    let value = 0;
    value = currentPrice > maxPrice ? maxPrice : currentPrice;
    setUsePrice(value);
    checkAllPoint(value); // 檢查是否全額
  };

  const checkAllPoint = (value) => {
    if (
      Number.parseInt(value) ===
        location.state.productTotal - location.state.discount &&
      location.state.orderTotal ===
        location.state.productTotal - location.state.discount
    ) {
      if (selectPayment !== 24) currentPayment.current = selectPayment;
      setSelectPayment(24);
    } else {
      setSelectPayment(
        selectPayment !== 24 ? selectPayment : currentPayment.current
      );
    }
  };

  const handlePointFormula = (point) => {
    const totalBonus = Math.floor(point / clpeRatio.current);
    setTotalBonus(totalBonus);
    setCanUsePrice(
      totalBonus <= location.state.hkDiscountPrice
        ? totalBonus
        : location.state.hkDiscountPrice
    );
  };

  const onSwitchPointUseButton = (event) => {
    setUsePointButtonState(event.target.checked);
    if (event.target.checked && usePrice === undefined) {
      setUsePrice(canUsePrice);
      checkAllPoint(canUsePrice);
    } else {
      setUsePrice(0);
      checkAllPoint(0);
    }
  };

  const onNeedValidCode = (code) => {
    validCode(code, (finished) => {
      setUsePointButtonState(finished);
    });
  };

  const confirm = () => {
    var saveData = {};

    if (selectPayment >= 1000) {
      const creditSelect = selectPayment - 1000;
      var expire = newCardInfo[creditSelect].expire;
      expire = expire.split('/');
      const expireMM = expire[0].replace(' ', '');
      const expireYY = expire[1].replace(' ', '');
      saveData = {
        ...payment,
        payment: 1,
        cardInfo: {
          cardNo: newCardInfo[creditSelect].cardNo,
          cvc: newCardInfo[creditSelect].cvc,
          expire: `20${expireYY}${expireMM}`,
          isNewCard: true,
          checked: newCardInfo[creditSelect].checked,
        },
      };
    } else if (selectPayment === 1) {
      saveData = {
        ...payment,
        payment: selectPayment,
        cardInfo: {
          cardNo: creditInfo.creditCardNumber ?? '',
          cvc: '',
          expire: `${creditInfo.creditCardExpirationYear ?? ''}${
            creditInfo.creditCardExpirationMonth ?? ''
          }`,
          isNewCard: false,
        },
      };
    } else {
      saveData = {
        ...payment,
        payment: selectPayment,
      };
    }

    if (saveData.payment === 1 && saveData.cardInfo.cardNo === '') {
      alert('請選擇支付方式');
      return;
    }

    if (usePointButtonState && usePrice < 0) {
      alert('紅利輸入錯誤');
      return;
    }

    saveData =
      usePointButtonState &&
      usePrice !== undefined &&
      usePrice !== '' &&
      usePrice !== 0 &&
      !Number.isNaN(usePrice)
        ? {
            ...saveData,
            clpe: {
              point: usePrice * 300,
              value: Number.parseInt(usePrice),
            },
          }
        : {
            ...saveData,
            clpe: undefined,
          };
    dispatch(actions.setPayment(saveData));
    navigate(-1);
  };

  useEffect(() => {
    if (payment.payment) {
      if (
        payment.payment === 1 &&
        payment.cardInfo &&
        payment.cardInfo.isNewCard
      ) {
        var findIndex = 0;
        if (newCardInfo.length > 0) {
          const result = newCardInfo.find((item) => {
            var expire = item.expire;
            expire = expire.split('/');
            const expireMM = expire[0].replace(' ', '');
            const expireYY = expire[1].replace(' ', '');
            return (
              item.cardNo === payment.cardInfo.cardNo &&
              item.cvc === payment.cardInfo.cvc &&
              `20${expireYY}${expireMM}` === payment.cardInfo.expire
            );
          });

          findIndex = newCardInfo.indexOf(result);
        }

        setSelectPayment(1000 + findIndex);
      } else setSelectPayment(payment.payment);
    }
  }, [payment]);

  // 如果之前有設定過紅利折抵，則用之前的設定
  useEffect(() => {
    if (location.state?.payment?.clpe) {
      setUsePrice(location.state.payment.clpe.value);
      setUsePointButtonState(true);
    }
  }, []);

  useEffect(() => {
    getPoint();
  }, []);

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'isInstalled') {
      setIsAppInstalled((state) => ({
        ...state,
        jko: dataJson.params.isInstalled,
      }));
    }
  };

  useEffect(() => {
    WebFn({ callback: jsBridgeCallback });
    callAppFunc('getIsAppInstalled', { appName: 'jkos' });
  }, []);

  const removeCreditCard = () => {
    console.log('removeCreditCard');
    api
      .removeCreditCard()
      .then((res) => {
        console.log('removeCreditCard', res);
        store.dispatch(actions.setCreditInfo({}));
        // store.dispatch(actions.setPaymentUpdate(true));
        localStorage.setItem('KeepPaymentType', JSON.stringify({ payment: 0 }));
        const saveData = {
          ...payment,
          payment: 0,
        };
        store.dispatch(actions.setPayment(saveData));
      })
      .catch((err) => {});
  };

  return (
    <>
      <HeaderBar
        title="付款方式"
        goBack={() => (addMode ? setAddMode(false) : navigate(-1))}
      />
      <div className="payment-option-page">
        {addMode ? (
          <form className="add-page" onSubmit={onCardSubmit}>
            <InputText
              label="卡號"
              text={cardNo}
              mLength="19"
              type="tel"
              onChange={onChangeCreditCard}
            />
            <div className="half">
              <InputText
                label="有效日期"
                text={expire}
                placeholder="MM/YY"
                mLength="7"
                type="tel"
                onChange={onChangeExpire}
              />
              <InputText
                label="安全碼"
                text={cvc}
                mLength="4"
                type="tel"
                onChange={onChangeCreditCVC}
              />
            </div>
            <div className="credit-notice">
              【因系統網頁功能調整，信用卡卡號請自行填入後進行結帳作業，如需記憶卡號請勾選設定快速結帳功能。】
            </div>
            <label className="checkbox">
              <input
                type="checkbox"
                name="1"
                value={checked}
                onChange={() => setChecked(!checked)}
              />
              <span>
                我同意本次付款資訊，並設定為快速結帳。(可節省您下次結帳時間，且保有修改、刪除之權利。)
              </span>
            </label>
            <div className="notice">
              本公司採用喬睿科技TapPay
              SSL交易系統，通過PCI-DSS國際信用卡組織Visa、MastreCard等產業資料安全Level
              1等級，周全保護您的信用卡資料安全。
            </div>
            <Button text="確認" type="submit" marginTop="15" />
          </form>
        ) : (
          <>
            <div className="discount-switch-box">
              <div className="header">
                <img
                  className="icon-notice"
                  src={IconNotice}
                  alt="notice"
                  width={20}
                  height={20}
                />
                尚差${limitPrice}可使用紅利折抵
              </div>
              <div className="content">
                <div className="title-text">
                  <span>點數折抵</span>
                  <img src={IconQuestion} alt="question-mark" />
                </div>
                <div className="discount-points">
                  <div className="max-amount">可折抵 {totalBonus} 元</div>
                  <div className="amount-box">
                    <Switcher
                      key={usePointButtonState}
                      id="enable-discount"
                      name="enable-discount"
                      onChange={onSwitchPointUseButton}
                      checked={usePointButtonState}
                      disabled={limitPrice > 0 || !isPointValid.current}
                    />
                    <div className="amount">
                      <input
                        key={usePointButtonState}
                        className="input-amount"
                        value={usePrice ? usePrice : ''}
                        disabled={limitPrice > 0 || !usePointButtonState}
                        onChange={onChangePriceValue}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.target.blur();
                          }
                        }}
                        enterKeyHint="done"
                      />
                      元
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`method-list ${selectPayment === 24 ? 'hidden' : ''}`}
            >
              {paymentMethods?.payByWallet === 1 && (
                <div className="method-option">
                  <label className="method-option-box">
                    <img src={IconWallet} alt="wallet" />
                    <Radio
                      id="payment-method-wallet"
                      name="payment-method"
                      value="20"
                      onChange={onChangePayment}
                      defaultValue={selectPayment}
                    />
                  </label>
                </div>
              )}
              {paymentMethods?.payByCreditCard === 1 && (
                <>
                  {creditInfo &&
                    Object.keys(creditInfo).length !== 0 &&
                    creditInfo.creditCardNumber !== '' && (
                      <>
                        <div className="method-option">
                          <label className="method-option-box">
                            信用卡
                            <Radio
                              id="payment-method-credit"
                              name="payment-method"
                              value="1"
                              onChange={onChangePayment}
                              defaultValue={selectPayment}
                            />
                          </label>
                          <div className="credit-number">
                            <div>
                              <img
                                className="credit-icon"
                                src={IconMastercard}
                                alt="credit"
                                width={24}
                              />
                              {creditInfo.creditCardNumber}
                            </div>
                            <div
                              className="remove-option"
                              onClick={() => removeCreditCard()}
                            >
                              刪除
                            </div>
                          </div>
                        </div>
                        <div className="method-option">
                          <div className="method-option-box">
                            <div>卡片到期日</div>
                            <div>{`${
                              creditInfo.creditCardExpirationMonth
                            }/${creditInfo.creditCardExpirationYear.substring(
                              creditInfo.creditCardExpirationYear.length - 2
                            )}`}</div>
                          </div>
                        </div>
                      </>
                    )}

                  {newCardInfo.map((card, index) => (
                    <div className="method-option" key={index}>
                      <label className="method-option-box">
                        信用卡
                        <Radio
                          id={`payment-method-credit-${index}`}
                          name="payment-method"
                          value={(1000 + index).toString()}
                          onChange={onChangePayment}
                          defaultValue={selectPayment}
                        />
                      </label>
                      <div className="credit-number">
                        <div>
                          <img
                            className="credit-icon"
                            src={IconMastercard}
                            alt="credit"
                            width={24}
                          />
                          {card.cardNo}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="method-option">
                    <div
                      className="method-option-box"
                      onClick={() => setAddMode(true)}
                    >
                      {creditInfo &&
                      creditInfo?.creditCardNumber !== '' &&
                      Object.keys(creditInfo).length !== 0
                        ? '更換信用卡'
                        : '信用卡支付'}
                      <img
                        className="add-new-card"
                        src={IconRightArrow}
                        alt="add new card"
                      />
                    </div>
                  </div>
                </>
              )}
              {isIOS && paymentMethods?.payByApplePay === 1 && (
                <div className="method-option">
                  <label className="method-option-box">
                    <img src={IconApplePay} alt="apple-pay" width={50} />
                    <Radio
                      id="payment-method-applepay"
                      name="payment-method"
                      value="12"
                      onChange={onChangePayment}
                      defaultValue={selectPayment}
                    />
                  </label>
                </div>
              )}
              {paymentMethods?.payByLinePay === 1 && (
                <div className="method-option">
                  <label className="method-option-box">
                    <img src={IconLinepay} alt="linepay" width={61} />
                    <Radio
                      id="payment-method-linepay"
                      name="payment-method"
                      value="14"
                      onChange={onChangePayment}
                      defaultValue={selectPayment}
                    />
                  </label>
                </div>
              )}
              {isAppInstalled.jko && paymentMethods?.payByJkoPay === 1 && (
                <div className="method-option">
                  <label className="method-option-box">
                    <img src={IconJko} alt="jko" width={70} />
                    <Radio
                      id="payment-method-jkopay"
                      name="payment-method"
                      value="19"
                      onChange={onChangePayment}
                      defaultValue={selectPayment}
                    />
                  </label>
                </div>
              )}
            </div>
          </>
        )}
        {/* 付款方式為 "錢包支付" 或 "紅利點數折抵" 的話才會顯示 */}
        {!addMode && (selectPayment === 20 || usePointButtonState) && (
          <div className="notice-text">
            <div>
              <span className="number">1.</span>
              <span>
                <span style={{ color: 'red' }}>
                  若使用紅利點數或家樂福錢包，則折抵後金額為0元，將不開立發票。
                </span>
                若您有報帳等需求，請選擇其他支付方式。
              </span>
            </div>
            <div>
              <span className="number">2.</span>
              <span>
                家樂福錢包付款是使用事先儲值進家樂福錢包的餘額進行付款。如欲使用請先確認已於APP開通錢包功能。(暫未開放Carrefour
                Pay刷卡付款)
              </span>
            </div>
            <div>
              <span className="number">3.</span>
              <span>
                家樂福錢包付款之金額，也可累積會員點數。家樂福錢包之優惠，線上購物與實體門市部份優惠活動不相同，請以活動公告為主。
              </span>
            </div>
            <div>
              <span className="number">4.</span>
              <span>
                家樂福錢包密碼若連續5次輸入錯誤，系統會自動鎖住，請重設密碼。
              </span>
            </div>
            <div>
              <span className="number">5.</span>
              <span>
                使用家樂福錢包消費後，若訂單取消或至原出貨店完成退貨流程後，原消費款項將立即退至家樂福錢包中；若至非出貨店退貨，則退回實體禮物卡。恕無法提供現金或其他方式退回，原若使用好康卡或家樂福信用卡所獲之紅利點數及其他優惠，亦將一併扣除。
              </span>
            </div>
          </div>
        )}
        <WalletDialog
          show={showWalletDialog}
          onClose={() => setShowWalletDialog(false)}
          price={1000}
          remain={500}
        />
        <MemberValidationDialog
          key={showValidationDialog}
          show={showValidationDialog}
          onClose={() => setShowValidationDialog(false)}
          onClick={onNeedValidCode}
          onClickResend={() => sendValidCode()}
        />
        <footer className="footer"></footer>
      </div>
      {!addMode && (
        <BottomSheet persistant noTitle noRounded>
          <Button
            text="確定"
            onClick={() => (addMode ? setAddMode(false) : confirm())}
          />
        </BottomSheet>
      )}
      <style jsx>
        {`
          .payment-option-page {
            padding: 16px 10px 100px;

            .add-page {
              padding: 10px;
              .half {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 10px;
                margin-bottom: 16px;
              }

              .checkbox {
                margin-bottom: 16px;
              }

              .notice {
                padding: 16px 20px;
                background: #f2f2f2;
                font-family: Noto Sans TC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #3b3516;
              }

              .credit-notice {
                padding: 16px 20px;
                font-family: Noto Sans TC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #ff0000;
              }
            }

            .discount-switch-box {
              border: 1px solid #e4e4e4;
              box-sizing: border-box;
              border-radius: 10px;
              margin-bottom: 16px;

              .header {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 21px;
                color: #3b3516;
                background: #d7f4f6;
                border-radius: 10px 10px 0px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px;

                .icon-notice {
                  margin-right: 5px;
                }
              }
              .content {
                padding: 14px 10px;

                .title-text {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 19px;
                  color: #333333;
                  margin-bottom: 4px;
                  display: flex;
                  align-items: center;

                  span {
                    margin-right: 5px;
                  }
                }
                .discount-points {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .max-amount {
                    display: inline-flex;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                    color: #333333;
                  }

                  .amount-box {
                    display: flex;
                    .amount {
                      margin-left: 16px;
                      .input-amount {
                        border: 1px solid #ebeae8;
                        width: 70px;
                        height: 32px;
                        border-radius: 4px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 21px;
                        color: #333333;
                        padding: 4px 8px;
                        margin-right: 2px;
                        text-align: right;
                      }
                    }
                  }
                }
              }
            }

            .method-list {
              max-height: 999px;
              overflow: hidden;
              transition: max-height 0.25s;
              &.hidden {
                max-height: 0;
              }
              .method-option {
                border-bottom: 1px solid #e4e4e4;
                padding: 16px 0;

                .method-option-box {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .add-new-card {
                    margin-right: 10px;
                  }
                }

                .credit-number {
                  margin-top: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .credit-icon {
                    margin-right: 8px;
                  }
                }
                .remove-option {
                  display: flex;
                  justify-content: flex-end;
                  font-size: 12px;
                  font-weight: 400;
                  color: #75a3f4;
                  margin-right: 8px;
                }
              }
            }

            .notice-text {
              padding: 10px;

              > div {
                display: flex;

                .number {
                  flex: 0 0 20px;
                }
              }
            }
          }

          .bottom-button {
            &.disabled {
              pointer-events: none;
            }
            width: calc(100vw - 24px);
            height: 60px;
            padding: 20px 12px;
            background-color: #ffffff;
            box-shadow: 0px 10px 15px 10px #e0e0e0;
            position: fixed;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .button-block {
              width: 100%;
              height: 60px;
              padding: 0 35px;
              background: #5fd2da;
              border: 1px solid #5fd2da;
              box-sizing: border-box;
              border-radius: 30px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 20px;
              line-height: 29px;
              color: #ffffff;
              .confirm-block {
                font-weight: bold;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: center;

                div {
                  width: 24px;
                  height: 24px;
                  background-color: #f9e05f;
                  font-size: 20px;
                  line-height: 29px;
                  text-align: right;
                  color: #3b3516;
                  border-radius: 50%;
                  margin-left: 6px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                span {
                  text-align: center;
                  display: block;
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default PaymentOptionPage;
